import axios from "axios";
import config from "../../public/config.json"

const ApiOmniwallet = axios.create({
  baseURL: config.api.url,
  withCredentials: true,
  timeout: 30000,
  
});

// console.log(config, "test1")

export default ApiOmniwallet;
